import React from "react";
import { buildFluidImageData } from "@imgix/gatsby";
import ButtonLink from "./ButtonLink";

import { Box, Text } from "tailwind-react-ui";
import Wonders from "../images/logos/Logo-7Wonders.png";

// Images
import SiteLogo from "../images/logos/site_logo.svg";

const Banner = (props) => {
  const image = props.image;
  const buttons = props.buttons;

  const imageFormatted = buildFluidImageData(`${image.url}`, {
    ar: 21 / 9, // required
    auto: ["format", "compress"],
    sizes: `100vw`, // optional, but highly recommended
    width: 2100,
    height: 900,
  });

  return (
    <>
      <div
        className="flex items-center flex-col relative bg-gray-200"
        bg="gray-200"
        data-component-type="banner"
        id="banner"
        style={{
          backgroundImage: `url(${imageFormatted.src})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: image.focalPoint ? `0` : "bottom",
          backgroundPositionX: image.focalPoint
            ? `${image.focalPoint[0] * 100}%`
            : "center",
          ar: 21 / 9,
        }}
      >
        <span className="sr-only">A banner resembling {image.title}</span>
        <div className="bg-radial absolute top-0 left-0 w-full h-full"></div>
        <img
          src={Wonders}
          width="75"
          className="absolute top-0 -mt-0.5 ml-auto md:mr-10 right-4"
          alt="7 Wonders of Nature"
          loading="lazy"
        />
        <Box className="relative w-full md:pr-40 mb-10 1xl:h-72 lg:h-80 lg:flex justify-between items-center">
          <Box className="relative  flex-auto w-full lg:w-1/2 2xl:ml-20 lg:ml-10 mt-20 xl:block hidden">
            <SiteLogo width="250" className="" alt="Site logo" />
          </Box>
          <Box className="relative lg:w-1/2 w-full flex flex-col xl:justify-center xl:items-start lg:items-end flex-auto xl:ml-64 md:mt-20 mt-10 ml-3 ">
            <Text
              text="white"
              size="lg"
              className="text-white 2xl:text-8xl xl:text-7xl md:text-6xl  text-5xl leading-3 font-heading w-full block"
              dangerouslySetInnerHTML={{ __html: props.subtitle }}
            />
            <Text
              text="secondary"
              size="lg"
              className="text-secondary 2xl:text-8xl xl:text-7xl md:text-6xl text-5xl leading-3 font-heading w-full block"
              dangerouslySetInnerHTML={{ __html: props.text }}
            />
          </Box>
        </Box>
        <Box className="md:mt-10 2xl:mt-44 w-full 2xl:ml-20 ml-5">
          {buttons.map((button, i) => (
            <ButtonLink
              key={`button${i}`}
              uri={
                button.relatedEntry[0]
                  ? "/" + button.relatedEntry[0].uri
                  : button.cutomUrl
                  ? button.cutomUrl
                  : button.customUrl
              }
              classes="ml-auto mr-5 lg:mt-8 my-5 md:inline-block block text-center home-banner-btn"
              text={button.text}
            />
          ))}
        </Box>
      </div>
    </>
  );
};

export default Banner;
