import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
import { buildFluidImageData } from "@imgix/gatsby";

// Layout
import Layout from "../components/Layout";

// Tailwind React UI Components
import {
  Box,
  Container,
  FillButton,
  Flex,
  Section,
  Text,
  Title,
} from "tailwind-react-ui";

// Custom Components
import Banner from "../components/Banner";
import IconTitle from "../components/IconTitle";
import LogoCarousel from "../components/LogoCarousel";
import SpecialBanner from "../components/Matrix/types/Independent/SpecialBanner";
import VideoBlock from "../components/Matrix/types/Independent/VideoBlock";

// Hard-coded image imports
import Girl from "../images/patterns/pattern-illustration-girl.svg";
import Squiggle from "../images/patterns/squiggle.svg";
import TriangleGY from "../images/patterns/patterns-triangles_greenyellow.svg";
import TrianglesGB from "../images/patterns/pattern-triangles_greenblue.svg";
import CableCar from "../images/icons/icon-cablecar.svg";
import FeaturedBlog from "../components/FeaturedBlog";

// GraphQL
export const query = graphql`
  query SiteQuery {
    home: craftHomeHomeEntry {
      title
      subtitle
      displayTitle
      metaTitle
      metaDescription
      metaKeywords
      metaImage {
        ...UploadAssetFragment
      }
      colouredIconBlocks {
        ... on Craft_colouredIconBlocks_block_BlockType {
          heading
          iconBackgroundColour
          icon {
            ...UploadIconAssetFragment
          }
          relatedEntry {
            uri
          }
        }
      }
      iconBlocks {
        ... on Craft_iconBlocks_block_BlockType {
          heading
          icon {
            ...UploadIconAssetFragment
          }
          relatedEntry {
            uri
          }
          typeHandle
        }
      }
      buttons {
        ... on Craft_buttons_button_BlockType {
          text
          cutomUrl
          relatedEntry {
            uri
          }
        }
      }
      featuredBlog {
        ... on Craft_blog_blog_Entry {
          featureImage {
            ...UploadAssetFragment
          }
          title
          summary
          uri
        }
      }
      featureImage {
        ...UploadAssetFragment
      }
      specialBanner {
        ...SpecialBannerFragment
      }
      awards {
        ... on Craft_awards_awards_Entry {
          awardLogo {
            ...UploadLogoAssetFragment
          }
          title
          description
          relatedEntry {
            uri
          }
        }
      }
      videoBlock {
        ...VideoBlockFragment
      }
      maintenanceMessage
    }
    plan: craftPlanYourVisitPlanYourVisitEntry {
      title
      subtitle
      postDate
      featureImage {
        ...UploadAssetFragment
      }
      summary
      typeHandle
    }
    blog: allCraftBlogBlogEntry(limit: 1) {
      nodes {
        title
        summary
        uri
        featureImage {
          ...UploadAssetFragment
        }
      }
    }
    siteInfo: craftSiteInformationGlobalSet {
      ...SiteInfoFragment
    }
  }
`;

const truncateString = (string = "", maxLength = 54) =>
  string.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

const Home = ({ data: { home, plan, blog, siteInfo } }) => {
  const subtitle = home.subtitle || siteInfo.siteTitle;
  const [siteImage] = siteInfo.siteImage;
  const [featureImage = siteImage] = home.featureImage;
  const [featuredBlog] = home.featuredBlog || blog.nodes;
  const metaImage = featureImage || home.metaImage || siteInfo.siteImage;
  const metaDescription = truncateString(
    (home.metaDescription || home.summary || siteInfo.siteDescription).replace(
      /(<([^>]+)>)/gi,
      ""
    ),
    159
  );
  const metaTitle = truncateString(
    `${home.metaTitle || home.displayTitle || home.title}`,
    53
  );
  const metaKeywords = home.metaKeywords || siteInfo.siteKeywords || null;
  const [showModal, setShowModal] = React.useState(false);

  useEffect(function () {
    if (typeof window !== "undefined") {
      if (!window.localStorage.getItem("hideNewsletter")) {
        setShowModal(true);
      }
    }
  }, []);

  function closeNewsletterModal() {
    window.localStorage.setItem("hideNewsletter", 1);
    setShowModal(false);
  }

  const metaImageFormatted = buildFluidImageData(`${metaImage.url}`, {
    ar: 1, // required
    auto: ["format", "compress"],
    sizes: `300px, 300px`, // optional, but highly recommended
    width: 300,
    height: 300,
  });

  const popupImageFormatted = buildFluidImageData(
    "https://tmac2021.imgix.net/uploads/awards/pop-up-image-4.png",
    {
      ar: 1.67, // required
      auto: ["format", "compress"],
      sizes: `1583px, 1440px, 1280px, 900px, 640px, 400px`, // optional, but highly recommended
      width: 791,
      height: 472,
    }
  );

  return (
    <Layout location={`/`} title={metaTitle}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        image={{
          height: 300,
          width: 300,
          ...metaImageFormatted,
        }}
        url={`/`}
        pathname={`/`}
      />
      <h1 style={{ fontSize: 0, color: "transparent" }} className="sr-only">
        {metaTitle}
      </h1>

      {featureImage && (
        <Section p="0" className="relative">
          <Banner
            title={siteInfo.siteTitle}
            subtitle={home.displayTitle}
            text={subtitle}
            image={featureImage}
            buttons={home.buttons}
          />
        </Section>
      )}

      <Section
        p="0"
        className="bg-primary"
        style={{
          background: 'url("/OPACITY-DOTS.png") repeat-y',
          backgroundSize: "auto 55%",
          backgroundPositionY: "top",
        }}
      >
        <div className="container mx-auto flex justify-center items-center h-64 md:h-32 overflow-y-hidden overflow-x-visible relative">
          <CableCar
            style={{
              height: "96px",
              width: "96px",
              transform: "translateY(-20px)",
            }}
            className="mr-2 hidden lg:block flex-none"
          />
          <h2
            className="flex-1 text-center sm:text-left block sm:flex items-center sm:pr-3 text-sm font-body text-white w-full my-auto xl:w-auto mx-auto mr-1"
            style={{ maxWidth: "1500px" }}
          >
            <strong>
              <span className="text-secondary uppercase">
                Annual Maintenance 2024:
              </span>{" "}
              Table Mountain Cableway shuts down for annual maintenance from 15
              July - 1 September 2024
            </strong>
          </h2>
          <TriangleGY
            style={{ height: "32px", width: "32px" }}
            className="mr-2 hidden lg:block"
          />
          <FillButton
            font="display"
            data-type="button"
            bg="secondary"
            bg-hocus="green"
            text="white"
            is="a"
            href="https://www.tablemountain.net/our-blog/cableway-announces-extended-annual-maintenance-shutdown-for-2024"
            text-hocus="white"
            className="transition duration-300 ease-in-out px-3 lg:px-5 py-3 rounded-full lg:text-lg"
          >
            Read&nbsp;more
            <span className="sr-only">Go to: Annual Maintenance 2024</span>
          </FillButton>
        </div>
      </Section>

      <Section p="0">
        <Container className="py-10 w-full lg:py-14 xl:w-5/6">
          <Flex wrap items="center" justify="between">
            <Box className="w-full lg:w-2/5">
              <Title
                size={5}
                level={2}
                font="heading"
                className="font-heading text-primary"
              >
                {plan.title || "Plan your visit"}
              </Title>
              <Text dangerouslySetInnerHTML={{ __html: plan.summary }} />
            </Box>

            <Box className="w-full lg:w-1/2">
              <Flex wrap justify="center">
                {home.colouredIconBlocks.map((block, i) => (
                  <Box key={i} className="w-1/3 md:w-1/4 lg:w-1/3 text-center">
                    <IconTitle
                      bg={block.iconBackgroundColour}
                      icon={block.icon[0]}
                      link={`/${
                        block.relatedEntry ? block.relatedEntry[0].uri : ""
                      }`}
                      text={block.heading}
                    />
                  </Box>
                ))}
              </Flex>
            </Box>
          </Flex>
        </Container>
      </Section>

      {home.maintenanceMessage && (
        <Section p="0" className="bg-blue-darker text-white text-center">
          <Container className="pt-10 pb-8 w-full lg:pt-14 lg:pb-12 xl:w-5/6 text-xl">
            <Flex wrap items="center" justify="center">
              <div
                dangerouslySetInnerHTML={{ __html: home.maintenanceMessage }}
              ></div>
            </Flex>
          </Container>
        </Section>
      )}

      <Section p="0" className="relative">
        <SpecialBanner block={home.specialBanner[0]} />
      </Section>

      <Section p="0">
        <Container className="w-full py-14 lg:py-20 xl:w-4/5">
          <Flex wrap align="stretch -mx-3" justify="center">
            <Box className="text-center lg:w-1/2">
              <Box className="shadow-lg mx-3 p-5 h-full">
                <div className="relative lg:w-4/5 mx-auto">
                  <VideoBlock block={home.videoBlock[0]} />
                </div>
                <FillButton
                  font="display"
                  data-type="button"
                  bg="secondary"
                  bg-hocus="secondary"
                  text="black"
                  is="a"
                  href={`${
                    home.videoBlock[0].relatedEntry[0]
                      ? "/" + home.videoBlock[0].relatedEntry[0].uri
                      : home.videoBlock[0].customUrl
                  }`}
                  text-hocus="black"
                  className="transition duration-300 ease-in-out px-3 lg:px-8 py-3 rounded-full mx-auto mb-3"
                >
                  {home.videoBlock[0].buttonText}
                </FillButton>
              </Box>
            </Box>
            <Box className="w-full lg:w-1/2">
              <Box className="shadow-lg p-5 mx-3 text-center flex flex-col h-full">
                <Title
                  level={4}
                  font="heading"
                  className="font-heading text-primary relative py-5 block"
                >
                  Visitor Information for:
                  <TrianglesGB
                    className="z-20 absolute top-full left-0"
                    alt="Pattern"
                  />
                </Title>
                <Flex wrap justify="between" className="my-auto">
                  {home.iconBlocks.map((block, i) => (
                    <Box
                      key={i}
                      className="w-1/3 md:w-1/4 lg:w-1/3 text-center"
                    >
                      <IconTitle
                        bg="white"
                        icon={block.icon[0]}
                        link={`/${
                          block.relatedEntry ? block.relatedEntry[0].uri : ""
                        }`}
                        text={block.heading}
                      />
                    </Box>
                  ))}
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Container>
      </Section>

      {/* Awards & Accolades */}
      <Section className="py-14 lg:py-20 relative bg-blue-dark">
        <Squiggle
          className="absolute top-16 -left-12 hidden lg:block"
          alt="Squiggle Pattern"
        />
        <Girl
          className="absolute top-5 right-0 hidden lg:block"
          alt="Girl with trophy"
        />
        <Title
          level={2}
          size={4}
          font="heading"
          className="font-heading md:text-4xl lg:text-5xl text-white text-center lg:pb-10 relative flex justify-center flex-start"
        >
          Awards &amp; Accolades
          <TriangleGY className="ml-6 -mt-1" alt="Green & Yellow Triangles" />
        </Title>
        <Container>
          <LogoCarousel block={home.awards} />
        </Container>
      </Section>

      {showModal ? (
        <>
          <Flex
            data-component-type="modal"
            className="justify-center items-stretch overflow-x-hidden overflow-y-hidden fixed inset-0 z-modal outline-none focus:outline-none"
          >
            <Box
              className="relative w-8/9 md:w-2/3 lg:w-8/9 m-auto overflow-y-auto shadow-2xl rounded-lg"
              style={{ maxWidth: "791px", aspectRatio: "791 / 472" }}
            >
              <FillButton
                font="display"
                text="secondary"
                p="0"
                className="absolute bg-transparent border border-transparent focus:outline-none focus:shadow-outline font-display inline-block leading-tight mb-2 ml-auto no-underline outline-none p-0 right-2 rounded select-none text-5xl text-secondary text-shadow z-50"
                onClick={() => closeNewsletterModal()}
              >
                ×
              </FillButton>
              <Link
                className="h-full"
                aria-label="Book your event/conference today!"
                to="/our-blog/table-mountain-cableways-vista-takes-exquisite-to-new-heights"
                target="_blank"
              >
                <Flex className="h-full border-0 rounded-lg relative w-full bg-blue outline-none focus:outline-none flex-col md:flex-row">
                  <Box
                    className="h-full relative p-6 lg:p-7 flex-fill w-full"
                    style={{
                      backgroundImage: `url(${popupImageFormatted.src})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPositionY: "center",
                      backgroundPositionX: "center",
                      ar: 1.67,
                    }}
                  ></Box>
                </Flex>
              </Link>
            </Box>
          </Flex>
          <div className="opacity-80 fixed inset-0 z-high bg-primary"></div>
        </>
      ) : (
        ""
      )}
    </Layout>
  );
};

export default Home;
